<template>
  <div>
    <div class="flex justify-between items-center mb-4">
      <VTitle :title="$t('app.view_lesson')" class="overview-title" />

      <div class="lesson-type">
        {{ $t(`app.${lesson.type}`) }}
      </div>
    </div>

    <VCard>
      <template #content>
        <VAlert
          v-if="content.length === 0"
          :text="$t('app.no_content_lesson')"
        />

        <div v-else>
          <div
            v-for="(text, index) in content"
            :key="index"
            :class="{ 'mt-4': index != 0 }"
          >
            <div class="flex justify-between items-center">
              <div>
                {{ getLangText(text.language) }}
              </div>

              <VButton
                icon="fal fa-eye"
                class="btn-primary"
                @click="onClickContent(text)"
              />
            </div>
          </div>
        </div>
      </template>
    </VCard>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "@/store";
// Composables
import useDownload from "@/composables/useDownload";
import useLesson from "@/composables/useLesson";
// Components
import VCard from "@/components/VCard";
import VAlert from "@/components/VAlert";
import VButton from "@/components/VButton";
import VTitle from "@/components/VTitle";

export default {
  components: {
    VCard,
    VTitle,
    VButton,
    VAlert
  },
  props: {
    lesson: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const store = useStore();

    const { isFile, isContentLocation } = useLesson();
    const { downloadFile } = useDownload();

    // Computed
    const langs = computed(() => store.getters["app/available_languages"]);

    const texts = computed(() => props.lesson?.texts ?? []);
    const type = computed(() => props.lesson?.type);
    const content = computed(() => {
      return texts.value.filter(text => {
        const hasFile = isFile(type.value) && text.content_file;
        // eslint-disable-next-line
        const hasLocation = isContentLocation(type.value) && text.content_location;

        return hasFile || hasLocation;
      });
    });

    // Methods
    const getLangText = langKey => {
      return langs.value[langKey] || "";
    };

    const redirectContent = url => {
      window.open(url, "_blank");
    };

    const downloadContent = content_file => {
      downloadFile(content_file.download_path);
    };

    const onClickContent = text => {
      if (isFile(type.value)) {
        downloadContent(text?.content_file);
        return;
      }

      if (isContentLocation(type.value)) {
        redirectContent(text?.content_location);
        return;
      }
    };

    return {
      getLangText,
      onClickContent,
      content,
      // useLesson
      isFile,
      isContentLocation
    };
  }
};
</script>
